* {
  font-family: 'Rubik', sans-serif;
}

.section1:hover{
  cursor: pointer;
}

@media screen and (max-width: 499px) {
  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;

  }
  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
  }

  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }

  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section3mainDiv {
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;

  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 40%;

  }

  .socialContainerTop {
    width: 100%;
  }

  .mM {
    width: 250px;
    height: 41px;
  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .oS {
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 13px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .allWrap {
    width: 100%;
    background-size: cover;
    background-image: url('./assets/bg.png');
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
        min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -1%;
  }

  .titlepic {
    width: 100px;
    height: 99px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
  }


  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 10px;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {}

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: auto;
    padding-right: auto;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 10%;
    border-width: 4px;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 100%;
    border-color: #53c245;

  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 27px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 50px;
    margin-top: 8px;
    padding-bottom: 10px;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }


  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }

  .tag1 {
    font-size: 25px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);


  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #54c24500;

  }

  .gif {
    border-radius: 7px;
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .pic {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2.5%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;


  }


  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;

  }
  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
  }

  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }

  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section3mainDiv {
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;

  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 30%;

  }

  .socialContainerTop {
    width: 100%;
  }

  .mM {
    width: 250px;
    height: 41px;
  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .oS {
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 13px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .allWrap {
    width: 100%;
    background-size: cover;
    background-image: url('./assets/bg.png');
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -1%;
  }

  .titlepic {
    width: 100px;
    height: 99px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
  }


  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {}

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: auto;
    padding-right: auto;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 10%;
    border-width: 4px;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 100%;
    border-color: #53c245;

  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 27px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 50px;
    margin-top: 8px;
    padding-bottom: 10px;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }


  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }

  .tag1 {
    font-size: 25px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);


  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #54c24500;

  }

  .gif {
    border-radius: 7px;
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .pic {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2.5%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;


  }


  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;

  }
  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
  }

  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }

  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section3mainDiv {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;

  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 20%;

  }

  .socialContainerTop {
    width: 80%;
  }

  .mM {
    width: 250px;
    height: 41px;
  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .oS {
    width: 180px;
    height: 180px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 13px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .allWrap {
    width: 100%;
    background-size: cover;
    background-image: url('./assets/bg.png');
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -1%;
  }

  .titlepic {
    width: 180px;
    height: 179px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
  }


  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {}

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 10%;
    border-width: 4px;
    width: 100%;
    border-color: #53c245;

  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 27px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: 8px;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }


  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }

  .tag1 {
    font-size: 25px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);


  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #54c24500;

  }

  .gif {
    border-radius: 7px;
    width: 180px;
    height: 180px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .pic {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2.5%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;


  }


  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 992px) and (max-width: 1269px) {
  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
  }

  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }

  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .section3mainDiv {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;

  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 20%;

  }

  .socialContainerTop {
    width: 80%;
  }

  .mM {
    width: 250px;
    height: 41px;
  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .oS {
    width: 220px;
    height: 220px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;

  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 13px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .allWrap {
    width: 100%;
    background-size: cover;
    background-image: url('./assets/bg.png');
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -1%;
  }

  .titlepic {
    width: 200px;
    height: 199px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
  }


  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    margin-left: auto;
    margin-right: auto;
  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {}

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 10%;
    border-width: 4px;
    width: 100%;
    border-color: #53c245;

  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 27px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: 8px;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }


  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }

  .tag1 {
    font-size: 25px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);


  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #54c24500;

  }

  .gif {
    border-radius: 7px;
    width: 220px;
    height: 220px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .pic {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2.5%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;


  }


  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1919px) {
  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }


  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 14px;
    color: #53c245;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 15px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .successfully {
    margin-top: 1%;
    font-size: 14px;
    color: #53c245;
    text-align: center;
  }


  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -4%;
  }

  .titlepic {
    width: 200px;
    height: 199px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }


  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    background-color: #429737;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    padding-right: 70px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {
    margin-right: 10%;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 25px;
    margin-top: 8px;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }

  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }

  .tag1 {
    font-size: 25px;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);
  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .socialContainerTop {
    width: 50%;
  }

  .mM {
    width: 250px;
    height: 41px;
  }


  .gif {
    border-radius: 7px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .oS {
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .pic {
    width: 160px;
    height: 160px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 1270px) and (max-width: 1919px) {

  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 20%;
    margin-left: auto;
    margin-right: auto;

  }

  .nftamount {
    color: white;
  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 15px;
    color: #ec5f5a;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 14px;
    color: #53c245;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 15px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .successfully {
    margin-top: 1%;
    font-size: 14px;
    color: #53c245;
    text-align: center;
  }

  .allWrap {
    width: 100%;
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./assets/bg.png');
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
  }

  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;

  }

  .tab2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
  }

  .tab1div {
    margin-right: 10%;

  }

  .section3mainDiv {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;

  }

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 10%;
    border-width: 4px;
    width: 40%;
    border-color: #53c245;
  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 27px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 50px;
    margin-top: 8px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }

  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }

  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 20px;
    text-align: center;
    border-radius: 5px;
    border-style: none;
    color: white;
    cursor: pointer;
    margin-bottom: 3%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }



  .tag1 {
    font-size: 25px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);

  }

  .tag2 {
    font-size: 11px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 30%;
  }


  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 30%;
  }

  .pic {
    width: 160px;
    height: 160px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
  }

  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;

  }

  .tab2:hover {
    color: rgb(165, 165, 165);
    cursor: pointer;
  }
}

@media screen and (min-width: 1920px) {
  .osImg{
    width: 40px;
    height: 40px;
    cursor: pointer;

  }
  .price {
    text-align: center;
    color: white;
    padding: 15px;
    font-size: 20px;
  }
  .note {
    color: black;
    text-align: center;
    padding-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }

  .note2 {
    color: black;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 3%;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: 500;
  }


  .nftamount {
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
  }
  .mintDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .walletConnect {
    margin-top: 3%;
  }

  .nftblockWalletConnectedALL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  a {
    color: #53c245;
    text-decoration: none;
  }

  .link {
    text-align: center;

  }

  .socialContainerTop {
    width: 30%;
  }

  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
  }

  .mM {
    width: 250px;
    height: 41px;
  }

  .lineBottom {
    background-color: black;
    width: 100%;

  }

  .iframe {
    width: 100%;
    height: 100vh;
  }

  .errorMessage {
    margin-top: 1%;
    font-size: 20px;
    color: #ec5f5a;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 27px;
    color: #53c245;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }

  .loadTextSub {
    text-align: center;
    font-size: smaller;
    color: rgb(185, 185, 185);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .successfully {
    margin-top: 1%;
    font-size: 14px;
    color: #53c245;
    text-align: center;
  }

  .allWrap {
    width: 100%;
    background-size: cover;
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./assets/bg.png');
    min-height: 100vh;
    height: max-content;
    overflow: hidden;
  }

  .title {
    text-align: center;
    font-size: 70px;
    margin-top: -1%;
  }

  .titlepic {
    width: 200px;
    height: 199px;
    border-radius: 100%;
    border-style: dashed;
    border-color: #53c245;
    border-width: 5px;
    cursor: pointer;
  }

  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
  }

  .left {
    flex-flow: row nowrap;
    display: flex;
    width: 25%;
  }

  .tab1 {
    padding: 15px;
    border-style: solid;
    border-radius: 20px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #429737;
    border: none;
    box-shadow: rgb(116, 116, 116) 0px 4px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    padding-right: 70px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .tab2 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 30px;

  }

  .tab1div {
    margin-right: 10%;

  }

  .section3mainDiv {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;

  }

  .section2 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    border-style: dashed;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 30px;
    border-width: 4px;
    width: 30%;
    border-color: #53c245;
  }

  .sec1Title {
    color: white;
    text-align: center;
    font-size: 35px;
  }

  .sec2Title {
    color: white;
    text-align: center;
    font-size: 50px;
    margin-top: 8px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .prisesSec1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: rgb(201, 199, 199);
    margin-top: 5%;
    font-size: 28px;
  }

  .line {
    height: 2px;
    width: 100%;
    background-color: #f8c46c;
    margin-top: 5%;
  }

 
  .mintButton {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-image: linear-gradient(to right top, #53c245, #52be44, #50ba43, #4fb742, #4eb341);
    font-size: 16px;
    text-align: center;
    color: white;
    border-radius: 5px;
    border-style: none;
    cursor: pointer;
    margin-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .mintButton:hover {
    background-image: linear-gradient(to right top, #56be48, #4dad40, #45aa38, #4fb742, #41a833);
  }

  .mintButtonDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }

  .bottomText {
    text-align: center;
    color: white;
    font-size: small;
    padding-top: 10px;
  }


  .tag1 {
    font-size: 50px;
    color: white;
    border: none;
    cursor: pointer;
    background-color: rgba(175, 175, 175, 0);

  }

  .tag2 {
    font-size: 27px;
    color: rgb(175, 175, 175);
  }

  .section3 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    width: 30%;
  }

  .gif {
    border-radius: 7px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

  }

  .oS {
    border-radius: 7px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 0px 20px 1px #53c245;
    border-radius: 50%;
    border-style: dashed;
    border-width: 5px;
    border-color: #53c245;

  }

  .section1 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 30%;
  }

  .pic {
    width: 250px;
    height: 250px;
    border-radius: 5px;
    transition: transform .2s;
  }

  .pic:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgb(219, 219, 219);

  }

  .collection {
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 4%;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    justify-content: center;
    margin-bottom: 3%;
  }

  .maxbtn {
    border-radius: 7px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    font-size: 28px;
  }

  .btnfos-0-2 {
    margin-right: 20px;
    margin-left: 20px;
    background-color: rgba(255, 222, 173, 0);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    background-color: #e48c28;
    padding: 5px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: rgb(116, 116, 116) 0px 4px;

  }

}